import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./subscription.module.css";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { deleteSubscription_api, get_all_Subscription } from "../api/subscription";
import { getBaseUrl2 } from "../../utils";

const SubscriptonList = () => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [allSubscription, setallSubscription] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [search, setsearch] = useState("");
    const [pageLength, setpageLength] = useState();
    const [month, setMonth] = useState((new Date().getMonth()) + 1)
    const [year, setYear] = useState(new Date().getFullYear())


    useEffect(() => {
        fetchAllSubscriptionList();
    }, [pageCount, search]);

    // console.log(allSubscription, "all media datas");

    const fetchAllSubscriptionList = async () => {
        setisLoading(true);
        let temp = {
            page: pageCount,
            limit: 8,
            search: search?.trim(),
        };
        try {
            let res = await get_all_Subscription(temp);
            if (res.data.status) {
                setallSubscription(res?.data?.data?.subscription);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("false Status!");
            }
        }
        catch (error) {
            setisLoading(false);
            console.log(error);
        }
    };
    async function deleteSubscriptionFunc() {
        setisLoading(true);
        let temp = {
            id: deleteId
        }
        try {
            let res = await deleteSubscription_api(temp);
            if (res.data.success) {
                setisLoading(false);
                setdeletePopup(false);
                fetchAllSubscriptionList();
                notificationHandler({ type: "success", msg: res?.data?.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    return (
        <>
            <div className="">
                <div className="beat_heading">
                    <div className={s["user-list-heading"]}>
                        <div className="user-list-title">
                            <h3>Subsctription List</h3>
                        </div>
                        {/* <div className = {s["user-list-search"]}>
              <div className = {s["search-box"]}>
                <span style = {{ paddingRight: "0.5rem" }}>
                  <BiSearch size={23} />
                </span>
                <input
                  type="text"
                  spellCheck = "false"
                  onChange={(e) => {
                    setpageCount(1);
                    setsearch(e.target.value);
                  }}
                  placeholder="Search by name..."
                />
              </div>
              <div className = {s["filter-btn"]}>
                <span style = {{ paddingRight: "2px" }}>
                  <BiFilter size = {20} />
                </span>
                Filter
              </div>
            </div> */}
                    </div>
                    <div className={s["beat_left"]}>
                        <div
                            className={s["title"]}
                            onClick={() =>
                                navigate("/add-subscription", {
                                    state: {
                                        pagetype: "Add",
                                    },
                                })
                            }
                        >
                            <IoMdAdd /> subscription
                        </div>

                    </div>
                </div>
                <div className="beat_table">
                    <Table aria-label="customized table" style={{ width: "130%" }}>
                        <TableHead>
                            <TableRow>

                                <StyledTableCell align="center">Image</StyledTableCell>
                                <StyledTableCell align="center">Name</StyledTableCell>
                                <StyledTableCell align="center">Hindi Name</StyledTableCell>
                                <StyledTableCell align="center">Place</StyledTableCell>
                                <StyledTableCell align="center">Hindi Place</StyledTableCell>
                                <StyledTableCell align="center">Subscription Plans</StyledTableCell>
                                <StyledTableCell align="center">Benefits</StyledTableCell>
                                <StyledTableCell align="center">Hindi Benefits</StyledTableCell>
                                <StyledTableCell align="center">FAQs</StyledTableCell>
                                <StyledTableCell align="center">Hindi FAQs</StyledTableCell>
                                {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        {/* <div style={{ margin: "0.5rem 0" }}></div> */}

                        <TableBody>
                            {allSubscription?.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell> {<img src={getBaseUrl2() + row?.thumbImage} />}</StyledTableCell>
                                    <StyledTableCell> {row?.name} </StyledTableCell>
                                    <StyledTableCell> {row?.hindiName} </StyledTableCell>
                                    <StyledTableCell> {row?.place}</StyledTableCell>
                                    <StyledTableCell> {row?.HindiPlace}</StyledTableCell>
                                    <StyledTableCell>
                                        {
                                            row?.plainItems?.map((data) => (
                                                <div>
                                                    <p><b>Price:</b>{data?.price}</p>
                                                    <p><b>Duration:</b>{data?.duration}</p>
                                                    --------------------------
                                                </div>
                                            ))
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "20rem" }}>
                                        {
                                            row?.benefits?.map((data) => (
                                                <div>
                                                    <p><b>Heading:</b>{data?.heading}</p>
                                                    <p><b>Value:</b>{data?.value}</p>
                                                    --------------------------------------
                                                </div>
                                            ))
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "20rem" }}>
                                        {
                                            row?.hindiBenefits?.map((data) => (
                                                <div>
                                                    <p><b>Heading:</b>{data?.heading}</p>
                                                    <p><b>Value:</b>{data?.value}</p>
                                                    --------------------------------------
                                                </div>
                                            ))
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "20rem" }}>
                                        {
                                            row?.faq?.map((data) => (
                                                <div>
                                                    <p><b>Heading:</b>{data?.heading}</p>
                                                    <p><b>Value:</b>{data?.value}</p>
                                                    --------------------------------------
                                                </div>
                                            ))
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "20rem" }}>
                                        {
                                            row?.hindiFaq?.map((data) => (
                                                <div>
                                                    <p><b>Heading:</b>{data?.heading}</p>
                                                    <p><b>Value:</b>{data?.value}</p>
                                                    --------------------------------------
                                                </div>
                                            ))
                                        }
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="center">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => language_status(row)}
                      className={`${
                        row.status === true ? s.active_admin : s.inactive_admin
                      }`}
                    >
                      {row.status === true ? "Active" :  "Inactive"}
                    </div>
                  </StyledTableCell> */}
                                    <StyledTableCell align="center">
                                        <CiEdit
                                            onClick={() =>
                                                navigate("/add-subscription", {
                                                    state: {
                                                        pagetype: "Edit",
                                                        data: row,
                                                    },
                                                })
                                            }
                                            style={{
                                                fontSize: "1rem",
                                                color: "var(--clr-primary)",
                                                marginRight: "0.5rem",
                                                cursor: "pointer",
                                            }}
                                        />
                                        <MdDelete
                                            onClick={() => {
                                                setdeletePopup(true);
                                                setcurrentGroup(row);
                                                setdeleteId(row._id);
                                            }}
                                            style={{
                                                fontSize: "1rem",
                                                color: "var(--clr-primary)",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {allSubscription?.length <= 0 && <DataNotFound />}
                    {allSubscription?.length > 0 && (
                        <div className={s["pagination"]}>
                            <Pagination
                                count={pageLength}
                                size="large"
                                style={{ color: "#D21903" }}
                                onChange={(e, value) => setpageCount(value)}
                                page={pageCount}
                            />
                        </div>
                    )}
                </div>
                <Dialog
                    open={deletePopup}
                    aria-labelledby="form-dialog-title"
                    maxWidth="xs"
                    fullWidth="true"
                    onClose={() => setdeletePopup(false)}
                >
                    <DialogTitle className={s.dialog_title}>
                        <div>Do you want to delete {currentGroup.name}?</div>
                    </DialogTitle>
                    <DialogContent className={s.cardpopup_content}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <div
                                className={s.employee_gl_popup}
                                onClick={() => setdeletePopup(false)}
                            >
                                Cancel
                            </div>
                            <div
                                className={s.employee_gl_popup_del}
                                onClick={() => deleteSubscriptionFunc()}
                            >
                                Delete
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions></DialogActions>
                </Dialog>
                <Loder loading={isLoading} />
            </div>
        </>
    );
};

export default SubscriptonList;
