import { Card } from "@mui/material";
import React from "react";
import { FiUsers } from "react-icons/fi";
import s from "./dashboard.module.css";
import Graph from "./Graph";
import Graph1 from "./Graph1";
const Home = () => {
  const cardArray = [
    {
      title: "Total Astrologer",
      icon: "2294",
    },
    {
      title: "Total Users",
      icon: "1584",
    },
    {
      title: "Today Users",
      icon: "1584",
    },
    {
      title: "Total Call's",
      icon: "1814",
    },
  ];
  return (
    <>
      <div style={{ paddingInline: "1rem" }}>
        <div className="dashboard-section">
          <div className={s["dashboard-header-container"]}>
            {cardArray.map((data) => (
              <Card className={s["dashbord-content"]}>
                <div class={s["dashboard-main-card"]}>
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>Total Users</h6>
                      <h4>2294</h4>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className={s["graph-container"]}>
          <div className={s["graph-content"]}>
            <div className="graph-left" style={{ width: "70%" }}>
              <Graph1 />
            </div>
            <div className="graph-right" style={{ width: "30%" }}>
              <Graph />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
