import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./subscriptionTransaction.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { getsubscriptionTransaction_api } from "../api/subscriptionTransaction";

const SubscriptionTransactionOuterList = () => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [subscriptionTransaction, setsubscriptionTransaction] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    const [slots, setSlots] = useState([
        {}
    ]);
    useEffect(() => {
        fetchsubscriptionTransactionFunc();
    }, [pageCount, search]);

    async function fetchsubscriptionTransactionFunc() {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 10,
                search: search.trim(),
            };
            let res = await getsubscriptionTransaction_api(temp);
            if (res.data.status) {
                setsubscriptionTransaction(res?.data?.data);
                setpageLength(res?.data?.meta?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));


    // const banner_status = async (data) => {
    //     setisLoading(true);
    //     const fd = new FormData();
    //     fd.append("id", data.id);
    //     fd.append("status", data.status == true ? false : true);
    //     try {
    //         let res = await statusUpdate_banner_api(fd);
    //         console.log(res);
    //         if (res.data.status) {
    //             setisLoading(false);
    //             fetchsubscriptionTransactionFunc();
    //             notificationHandler({ type: "success", msg: res.data.message });
    //         } else {
    //             setisLoading(false);
    //             notificationHandler({ type: "danger", msg: res.data.message });
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         notificationHandler({ type: "danger", msg: error.message });
    //     }
    // };
    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3> Subscritpion Transaction List</h3>
                    </div>
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell align="center">Image</StyledTableCell> */}
                            <StyledTableCell align="center">User Details</StyledTableCell>
                            <StyledTableCell align="center">Transaction ID</StyledTableCell>
                            <StyledTableCell align="center">Order Id</StyledTableCell>
                            <StyledTableCell align="center">Amount</StyledTableCell>
                            <StyledTableCell align="center">Duration</StyledTableCell>
                            <StyledTableCell align="center">Event Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Create Date</StyledTableCell>
                            <StyledTableCell align="center">Expiry Date</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptionTransaction?.map((row) => (
                            <StyledTableRow key={row.id}>
                                {/* <StyledTableCell>
                                    {row?.item?.thumbImage ? <img style={{ height: "2rem", width: "3rem" }} src={getBaseUrl2() + row?.item?.thumbImage} alt="" /> : null}
                                </StyledTableCell> */}
                                <StyledTableCell>
                                    <div>
                                        <p><b>Name:-</b>{row?.user?.name}</p>
                                        <p><b>Phone No.:-</b>{row?.user?.phone}</p>
                                    </div>
                                     </StyledTableCell>
                                <StyledTableCell align="center">{row?.transactionId} </StyledTableCell>
                                <StyledTableCell align="center">{row?.orderId} </StyledTableCell>
                                <StyledTableCell align="center">{row?.totalPrice} </StyledTableCell>
                                <StyledTableCell align="center">{row?.duration} </StyledTableCell>
                                <StyledTableCell align="center">{row?.pujaDate.slice(0, 10)} </StyledTableCell>
                                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => banner_status(bankDetail)}
                    className={`${row.status === "pending" ? s.inactive_admin : s.active_admin}`}
                  >
                    {row?.status}
                  </div>
                </StyledTableCell>
                                <StyledTableCell align="center">{row?.createdAt?.slice(0, 10)} </StyledTableCell>
                                <StyledTableCell align="center">{row?.expiryDate?.slice(0, 10)} </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {subscriptionTransaction?.length <= 0 && <DataNotFound />}
                {subscriptionTransaction?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>

            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.category_name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} >
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div>
    );
};

export default SubscriptionTransactionOuterList;
