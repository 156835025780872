import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./user.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Pagination } from "@mui/material";
import { fetchAllTransaction, fetchAllUsers, user_delete_api, user_status_api } from "../api/user";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiArrowBack, BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import { getBaseUrl, getBaseUrl2 } from "../../utils";
import UploadVideoUrl from "./UploadVideoUrl";

const TransactionList = () => {
    const navigate = useNavigate();
    const location = useLocation()

    console.log(location.state.data, " this is get in the page of the transaction listing")
    const [isLoading, setisLoading] = useState(false);
    const [allUsers, setallUsers] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletedialobbox, setdeletedialobbox] = useState(false);
    const [deletename, setdeletename] = useState();
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    const [uploadVideoModal,setuploadVideoModal] =useState(false)
const [transactionId,settransactionId]=useState("")

    useEffect(() => {
        fetchAllUsersFunc();
    }, [pageCount, search,uploadVideoModal]);

    async function fetchAllUsersFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 8,
                search: search.trim(),
            };
            let res = await fetchAllTransaction(location.state.data._id);
            if (res.data.status) {
                setallUsers(res.data.data);
                setpageLength(res?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteuserFunc() {
        setisLoading(true);
        let temp = {
            id: deleteId,
        };
        try {
            let res = await user_delete_api(temp);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                setdeletedialobbox(false);
                // fetchAllUsersFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }

    const user_status = async (data) => {
        setisLoading(true);
        let temp = {
            id: data._id,
            status: data.status == true ? false : true
        }
        try {
            let res = await user_status_api(temp);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                fetchAllUsersFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    };

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Transaction  List</h3>
                    </div>

                    <div className={s["user-list-search"]}>
                        {/* <div className={s["search-box"]}>
                            <span style={{ paddingRight: "0.5rem" }}>
                                <BiSearch size={23} />
                            </span>
                            <input
                                type="text"
                                spellCheck="false"
                                onChange={(e) => {
                                    setpageCount(1);
                                    setsearch(e.target.value);
                                }}
                                placeholder="Search users by name.."
                            />
                        </div> */}
                    </div>
                </div>
                <div className="main-heading-left">
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Transaction Id</StyledTableCell>
                            <StyledTableCell align="center">Amount</StyledTableCell>
                            <StyledTableCell align="center">Created</StyledTableCell>
                            <StyledTableCell align="center">Url</StyledTableCell>
                            <StyledTableCell align="center">Upload Video</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allUsers.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center">{row.transactionId} </StyledTableCell>
                                <StyledTableCell align="center">{row.amount} </StyledTableCell>
                                <StyledTableCell align="center">{row.created_at.slice(0, 10)}</StyledTableCell>
                                <StyledTableCell align="center">
                                <div style={{cursor:"pointer"}}>
                                   <a href={row?.videoUrl} target="_blank"> {row?.videoUrl || "Not Uploaded"}</a>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <div
                                        className={s["title"]}
                                        onClick={() =>{
                                             settransactionId(row._id)
                                            setuploadVideoModal(true)
                                        }
                                        }
                                    >
                                        UPLOAD
                                    </div>
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                    <div style={{ cursor: "pointer" }} onClick={() => user_status(row)} className={`${row.status === "success" ? s.active_admin : s.inactive_admin}`}>
                                        {row.status}
                                    </div>
                                </StyledTableCell> */}
                                {/* <StyledTableCell align="center">
                                    <div
                                        className={s["title"]}
                                        onClick={() =>
                                            navigate("/transaction-list", {
                                                state: {
                                                    pagetype: "Add",
                                                    id: row
                                                },
                                            })
                                        }
                                    >
                                        Transaction
                                    </div>
                                </StyledTableCell> */}
                                {/* <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/useradd", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletedialobbox(true);
                      setdeletename(row.user_name);
                      setdeleteId(row.id);
                    }}
                    style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                  />
                </StyledTableCell> */}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allUsers.length <= 0 && <DataNotFound />}
                {allUsers?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>
            <DeletePopup
                open={deletedialobbox}
                name={deletename}
                close={() => setdeletedialobbox(!deletedialobbox)}
                onsubmit={() => {
                    deleteuserFunc();
                }}
            />
            <Loder loading={isLoading} />
            <UploadVideoUrl open={uploadVideoModal} setOpen={setuploadVideoModal} userId={location?.state?.data?._id} subscriptionTransactionId={transactionId} type={"transaction"}  />
        </div>
    );
};

export default TransactionList;
