import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";
import { json } from "react-router-dom";

let subscripion_list = getBaseUrl() + "admin/pujaSubscription";
let add_subscription = getBaseUrl() + "admin/pujaSubscription";
let update_subscription = getBaseUrl() + "admin/pujaSubscription"
let delete_subscription = getBaseUrl() + "admin/pujaSubscription";

export const get_all_Subscription = async (data) => {
    let config = {
        method: "get",
        url: subscripion_list,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` }
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}


export const addSubscription_api = async (data) => {
    console.log(data, "data onhte page of js888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888")
    let config = {
        method: "post",
        url: add_subscription,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}
export const updateSubscription_api = async (data) => {
    let config = {
        method: "patch",
        url: update_subscription,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}

export const deleteSubscription_api = async (data) => {
    let config = {
        method: "delete",
        url: delete_subscription,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}

